import React from "react";
import { Input, styled } from "@100mslive/react-ui";
import { isStreamingKit } from "../../common/utils";

const PreviewName = ({ name, onChange, onJoin, enableJoin }) => {
  const formSubmit = e => {
    e.preventDefault();
  };
  return (
    <Form onSubmit={formSubmit}>
      {
        !window.document.URL.includes("name=") ?
          <div className="flex justify-center w-full">
            <div className="flex">
              <Input
                required
                id="name"
                css={{ w: "100%" }}
                value={name}
                onChange={e => onChange(e.target.value)}
                placeholder="Enter your name"
                autoFocus
                autoComplete="name"
              />
            </div>
            <div className="flex ml-10 w-32 h-10 rounded-xl bg-[#289efd] justify-center items-center cursor-pointer text-white" type="submit" disabled={!name || !enableJoin} onClick={onJoin}>
              <span className="-mt-1">{isStreamingKit() ? "Join Studio" : "Join Call"}</span>
            </div>
          </div> :
          <div className="flex justify-center w-full">
            <div className="flex mt-2">
              <span className="text-white" onLoad={onChange(decodeURI(window.document.URL.split("name=")[1]).replace("+", ""))}>{decodeURI(window.document.URL.split("name=")[1]).replace("+", "")}</span>
            </div>
            <div className="flex ml-10 w-32 h-10 rounded-xl bg-[#289efd] justify-center items-center cursor-pointer text-white" type="submit" disabled={!name || !enableJoin} onClick={onJoin}>
              <span className="-mt-1">{isStreamingKit() ? "Join Studio" : "Join Call"}</span>
            </div>
          </div>
      }
    </Form>
  );
};

const Form = styled("form", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  gap: "$4",
  mt: "$10",
  mb: "$10",
});

export default PreviewName;
